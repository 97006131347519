:root {
  --purple: #834be1;
  --purple2: #492b7c;
  --yellow: #ffda00;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-purple {
  color: var(--purple);
}

.bg-purple {
  background-color: var(--purple);
}

.main {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 13%;
}

.main h1 {
  font-size: 42px;
  color: var(--purple2);
  margin-top: 30px;
}

.main .btn-dp {
  margin-top: 20px;
  position: relative;
  z-index: 99;
}

.btn-dp {
  background-color: var(--purple);
  color: white;
  border: 0;
  font-size: 25px;
  padding: 10px 50px;
}

.btn-dp:active,
.btn-dp:focus,
.btn-dp:hover {
  background-color: var(--purple2);
}

.main .highlight {
  background: var(--yellow);
}

.hiw {
  position: relative;
  /* background-color: var(--yellow); */
  background: no-repeat left/55% url("./assets/moca.png") var(--yellow);
  border: 1px solid white;
  padding-bottom: 80px;
}

.hiw:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;

  right: 0;

  border-top: 90px solid white;
  border-left: 2000px solid transparent;
}

.hiw .text {
  text-align: right;
}

.hiw h2 {
  font-size: 42px;
  color: #492b7c;
  font-weight: bold;
  margin-top: 80px;
}

.hiw p {
  color: var(--purple);
  font-size: 23px;
  line-height: 1.5;
}

.hiw h3 {
  font-size: 42px;
  color: var(--purple);
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 60px;
}

.hiw .highlight {
  background: var(--purple);
  color: white;
}

.logo-circle {
  width: 130px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  margin-top: 30px;
}

@media (max-width: 1100px) {
  .hiw {
    background: var(--yellow);
  }

  .hiw .text {
    text-align: center;
  }

  .hiw h2,
  .hiw h3 {
    font-size: 30px;
  }
}

.descr {
  position: relative;
  background: no-repeat center/60% url("./assets/stores.png");
  padding-top: 140px;
  padding-bottom: 140px;
}

.descr p {
  font-size: 28px;
  line-height: 1.2;
  color: var(--purple);
}

.descr p span {
  font-weight: bold;
  color: var(--purple2);
}

.descr p:nth-child(2) {
  text-align: right;
  margin-top: 200px;
}

@media (max-width: 1100px) {
  .descr {
    background: no-repeat center url("./assets/stores-low-opacity.png");
  }

  .descr p,
  .descr p:nth-child(2) {
    text-align: center;
  }
}

.contact {
  background: no-repeat left/50% url("./assets/shopping.png") var(--purple2);
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact .form-container {
  position: relative;
  width: 30%;
  left: 60%;
  text-align: center;
}

.contact h3 {
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: bold;
}

.contact p {
  font-size: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1100px) {
  .contact {
    background: var(--purple2);
  }

  .contact .form-container {
    position: relative;
    width: 100%;
    left: 0;
    text-align: center;
  }
}
